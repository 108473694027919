html {
  font-size: 62.5%;
  box-sizing: border-box;
  /* converted root fontsize to 62.5% to allow rem values be in base the >>>>  100px = 10rem */
}

body {
  transition: 1s ease-in-out scroll;
}

/* education and experiences */
.gridContainer {
  grid-template-areas:
    'saed KodeCamp'
    'Perxels Tech4dev'
    'Obafemi Hub';
}

@media screen and (max-width:992px) {
  .gridContainer {
    grid-template-areas:
      'saed'
      'KodeCamp'
      'Perxels'
      'Tech4dev'
      'Obafemi'
      'Hub'
  }
}

.linesborder {

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25'  xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='268' ry='268' stroke='rgba(175, 1, 22, .2)' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 268px;

}

@media screen and (max-width:900px) {
  .linesborder {

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25'  xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='150' ry='150' stroke='rgba(175, 1, 22, .2)' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 150px;

  }
}